import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import { Subject, from } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastService } from '../../toast.service';


@Component({
  selector: 'app-upload-claims-manual',
  templateUrl: './upload-claims-manual.component.html',
  styleUrls: ['./upload-claims-manual.component.css']
})
export class UploadClaimsManualComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  resstatus = localStorage.getItem('resstatus');

  validmsg: any;
  template = ``;
  show = false;
  fullScreen = true;
  public basicInfo: FormGroup;
  basicInfoSubmitted: boolean = false;
  companyDetails: any = [];
  policy_type: any = [];
  policynumbers: any = [];
  document_file: any;
  downloadbuttontooltip: any;
  samplefile: any;
  samplefileName: any;
  Policyuploaddoclist: any = [];

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private service: Service,
    private fb: FormBuilder,
    private route: Router,
    private toastService: ToastService
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();
    // $('#defaultOpen')[0].click()

    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 2000);


  }

  initGroup() {

    setTimeout(() => {
      this.getcompanyDetails();
    }, 1000);

    $('#validmsg').css('display', 'none');
    this.basicInfo = this.fb.group({
      company_id: new FormControl(null, Validators.required),
      policy_type: new FormControl('', Validators.required),
      policy_number: new FormControl('', Validators.required),
      typeofdata: new FormControl('inception', Validators.required),
      document_file: new FormControl(''),
      // client_branch_id: new FormControl(''),
    });
  }
  get getBasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  getcompanyDetails() {
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
    }
    this.service.getcompanyDetails(postdata)
      .subscribe((result) => {

        this.companyDetails = result.data;
      }, (error) => {
        // console.log(result);

        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/login']);
        }
      });
  }

  getpolicytype() {

    this.show = true;

    setTimeout(() => {
      $("#download_sample_file").css("display", "none");
      this.basicInfo.controls['policy_number'].setValue('');
      this.basicInfo.controls['policy_type'].setValue('');
      // $("#policy_type").val('');
      this.show = false;
    }, 1000);


    this.getPolicyuploaddoclist();

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "client_id": this.basicInfo.controls['company_id'].value,
    }
    this.service.getpolicytype(postdata)
      .subscribe((result) => {

        this.policy_type = result.data;

      }, (error) => {
        // console.log(result);
      });
  }

  getpolicynumbers() {

    this.show = true;

    setTimeout(() => {
      // $("#policy_number").val('');
      this.basicInfo.controls['policy_number'].setValue('');

      $("#download_sample_file").css("display", "none");
      this.show = false;

    }, 1000);

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "client_id": this.basicInfo.controls['company_id'].value,
      "sub_type_id": this.basicInfo.controls['policy_type'].value,
    }
    this.service.getpolicynumbers(postdata)
      .subscribe((result) => {

        this.policynumbers = result.data;

      }, (error) => {
        // console.log(result);
      });
  }

  getsamplefile() {


    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "master_policies_id": this.basicInfo.controls['policy_number'].value,
      "sub_type_id": this.basicInfo.controls['policy_type'].value,
    }
    this.service.getsamplefile(postdata)
      .subscribe((result) => {
        if (result.status) {
          $("#download_sample_file").css("display", "block");
          this.samplefile = result.data['policy_member_sample'];
          this.samplefileName = result.data['sample_file'];
        } else {
          $("#download_sample_file").css("display", "none");
        }
      }, (error) => {
        console.log(error);
      });

  }

  openCity(evt: any, cityName: any) {
    console.log(evt);
    console.log(cityName);

    var i, tabcontent, tablinks;
    tabcontent = $(".tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    $("#" + cityName).css("display", "block")
    evt.currentTarget.className += " active";

  }

  ondownload(val: any) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    if (val == "policyfeatures") {
      link.setAttribute('href', this.samplefile);
      link.setAttribute('download', this.samplefileName);
    }

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  // uploadfile(event: any){
  //   if(event.target.files.length > 0){
  //     this.document_file = event.target.files[0];
  //     $("#features-file-upload_document_file").empty();
  //     $('#features-file-upload_document_file').html(event.target.files[0]['name']);
  //     $('#features-file-upload_document_file').css('text-align', 'left');

  //     var iSize = Math.round(event.target.files[0]['size'] / 1024);

  //     iSize = (Math.round(iSize * 100) / 100);

  //     $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


  //   }
  // }

  uploadfile(event: any) {
    const errorElement = document.getElementById('document_file_error') as HTMLElement;

    if (event.target.files.length > 0) {
      const allowedExtensions = ['xls', 'xlsx', 'csv'];
      const file = event.target.files[0];
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        errorElement.textContent = 'Invalid file type. Please upload a .xls, .xlsx, or .csv file.';
        errorElement.style.display = 'block';  // Show error message
        return;
      }

      errorElement.style.display = 'none';  // Hide error message if valid file

      this.document_file = file;
      $("#features-file-upload_document_file").empty();
      $('#features-file-upload_document_file').html(fileName);
      $('#features-file-upload_document_file').css('text-align', 'left');

      let iSize = Math.round(file.size / 1024);
      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">' + iSize + 'kb</span>');
    }
  }

  onSubmitAdd() {

    if (this.document_file == "" || this.document_file == null || this.document_file == undefined) {
      $('#document_file_error').css('display', 'block');
      $('#features-file-upload_document_file').css('border', '1px solid red');

    } else {

      $('#document_file_error').css('display', 'none');
      $('#features-file-upload_document_file').css('border', '1px solid #dee2e6');
    }

    this.basicInfoSubmitted = true;
    if (this.basicInfoSubmitted && this.basicInfo.valid) {


      if (this.document_file == "" || this.document_file == null || this.document_file == undefined) {
        $('#document_file_error').css('display', 'block');
        $('#features-file-upload_document_file').css('border', '1px solid red');

        return;
      } else {

        $('#document_file_error').css('display', 'none');
        $('#features-file-upload_document_file').css('border', '1px solid #dee2e6');
      }

      this.show = true;

      var postdata = {
        "tokenable_id": localStorage.getItem('tokenable_id'),
        "client_id": this.basicInfo.controls['company_id'].value,
        "broker_id": localStorage.getItem('broker_id'),
        "policy_type": this.basicInfo.controls['policy_type'].value,
        "policy_number": this.basicInfo.controls['policy_number'].value,        
        document_file: this.document_file
      };
      console.log(postdata);
      
      this.service.uploadsamplefile(postdata)
        .subscribe((result) => {
          if (result.status) {
            this.show = false;
            this.validmsg = result.message;         
            this.toastService.showSuccess('Success', 'Details Saved Successfully!');
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            // this.route.navigate(['member-upload']);
          }else{
            this.show = false;
          }
        }, (error) => {
          // console.log(error);
          this.show = false;
          this.toastService.showFailed('Failed', 'There was some error');

        });

    }

  }

  getPolicyuploaddoclist() {
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
      "company_id": this.basicInfo.controls['company_id'].value,
    }
    this.service.getPolicyuploaddoclist(postdata)
      .subscribe((result) => {

        this.Policyuploaddoclist = result.data;

        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.clear();
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      }, (error) => {
        // console.log(result);
        this.Policyuploaddoclist = [];
      });
  }

  ondownload_doc(val: any) {
    window.open(val, '_blank');
  }

  ondownload_doc_failed(id: any) {

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "uploadid": id,
    }
    this.service.downloadfailedDcoument(postdata)
      .subscribe((result) => {

        // console.log(result);

        window.open(result.data.error_filename, '_blank');

        // const link = document.createElement('a');
        // link.setAttribute('target', '_blank');

        // link.setAttribute('href', result.error_filename);
        // link.setAttribute('download', 'Document.xls');

        // document.body.appendChild(link);
        // link.click();
        // link.remove();

      }, (error) => {
        // console.log(result);
      });

  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}

