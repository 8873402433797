import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { ServiceEmployee } from './service';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-employeruser-dashboard',
  templateUrl: './employeruser-dashboard.component.html',
  styleUrls: ['./employeruser-dashboard.component.css']
})
export class EmployeruserDashboardComponent implements OnInit {

  json: any = [
    {
      title: "My Family & Benefits",
      description: "Enroll Members",
      imageURL: "Group 3.png",
      linkURL: "",
      bgColor: "#005963"
    },
    {
      title: "My Services",
      description: "Enroll Members",
      imageURL: "benefit.png",
      linkURL: "",
      bgColor: "#00ACB1"
    },
    {
      title: "My Wellnes",
      description: "Enroll Members",
      imageURL: "wellness.png",
      linkURL: "",
      bgColor: "#C8535E"
    },
    {
      title: "My Personal Care",
      description: "Enroll Members",
      imageURL: "call_center.png",
      linkURL: "",
      bgColor: "#2C2C2C"
    }
  ];

  show = false;
  fullScreen = true;
  template = ``;

  InfoArray: any = [];
  Employepolicyes: any = [];
  openpolicies: any = [];
  dashboard_content:any;
  dashboard_video_link: string;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');
  user_name = localStorage.getItem('name');

  health:any = [];
  risk:any = [];

  constructor(private fb: FormBuilder, private dataservice: ServiceEmployee, private route: Router, private sanitizer: DomSanitizer) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup() {
    this.getInfo();
    this.getemployeepolicies();
  }

  getInfo() {

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
    }

    this.dataservice.getInfo(postdata)
      .subscribe((result: any) => {

        this.show = false;

        // console.log(result);
        this.InfoArray = result.data.dashboard;
        this.dashboard_content = result.data.dashboard_content;
        this.dashboard_video_link = result.data.dashboard_video_link;

      }, (error) => {
        this.show = false;

        if (error.error.message == 'Unauthenticated.') {
          this.show = false;
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/login']);
        }

        this.InfoArray = [];

      });
  }

  navigateto(id: any) {    
    localStorage.setItem('dashboard_id', id);
    if (id == 1) {
      // this.route.navigate(['/employeruser-dashboard/family-contruct']);
      // this.route.navigate(['/employeruser-dashboard/family-enrollment']);
      // this.route.navigate(['/flex-plan']);
      let show_dashboard = localStorage.getItem('show_dashboard');
      console.log(show_dashboard);

      if (show_dashboard === '0' || show_dashboard === null) {
        this.route.navigate(['/flex-configurator-dashboard']);
      }else{
        this.route.navigate(['/flex-plan']);
      }
    }
    if (id == 2) {
      this.route.navigate(['/employeruser-dashboard/employee_benefit']);
    }

    if (id == 3) {
      this.route.navigate(['/well-being']);
    }

    if (id == 4) {
      this.route.navigate(['/personal-care']);
    }
   
  }

  // Inside your component class
  getBackgroundColor(index: number): string {
    // Define your logic here to return different background colors based on the index
    if (index == 0) {
      return '#005963';
    } else if (index == 1) {
      return '#00ACB1';
    } else if (index == 2) {
      return '#C8535E';
    } else {
      return '#2C2C2C';
    }
  }

  getemployeepolicies(){

    this.show = true;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')      
    };

    this.dataservice.getemployeepolicies(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {
        
        this.show = false;
        this.Employepolicyes = result.data.dashboard_details;   
        
        for(var i = 0; i<result.data.dashboard_details.length; i++){
          if(result.data.dashboard_details[i].enrolement_btn == 'OPEN'){
            this.openpolicies.push(result.data.dashboard_details[i]);
          }
          if(result.data.dashboard_details[i].type_name == 'Mediclaim'){
            this.health.push(result.data.dashboard_details[i]);            
          }
          if(result.data.dashboard_details[i].type_name == 'Risk'){
            this.risk.push(result.data.dashboard_details[i]);            
          }
        }

        // if(result.data.show_dashboard == '0'){
        //   this.gotohome();
        // }else{

        // }


      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          this.show = false;
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  // Sanitize the URL
  getSafeUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.dashboard_video_link);
  }

  gotohome(){
    this.route.navigate(['/flex-configurator-dashboard']);
  }

}
