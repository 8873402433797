<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title" style="line-height: unset;">Claim View
                <button type="button" class="btn btn-primary" (click)="backToClaims()" role="button"
                    style="float:right">Back</button>
            </h3>

            <div class="accordion mt-5" id="policyAccordion">

                <!-- Claim Details -->
                <div class="accordion-item mt-4">
                    <h2 class="accordion-header" id="claimDetailsHeading">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#claimDetails" aria-expanded="true">
                            Claim Details
                        </button>
                    </h2>
                    <div id="claimDetails" class="accordion-collapse collapse show" data-bs-parent="#policyAccordion">
                        <div class="accordion-body">
                            <table class="table">
                                <tr>
                                    <td class="labeltd">Claim ID:</td>
                                    <td class="valuetd">{{claimId}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Claim Status:</td>
                                    <td class="valuetd">{{claimStatus}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Claim Sub Status:</td>
                                    <td class="valuetd">{{claimSubStatus}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Relationship With Employee:</td>
                                    <td class="valuetd">{{Relation}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">TPA Name:</td>
                                    <td class="valuetd">{{TPA_name}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Claimed Amount:</td>
                                    <td class="valuetd">{{ClaimedAmount}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Hospital Name:</td>
                                    <td class="valuetd">{{HospitalName}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Hospital Address:</td>
                                    <td class="valuetd">{{HospitalAddress}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Hospital City:</td>
                                    <td class="valuetd">{{hospital_city}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Hospital State:</td>
                                    <td class="valuetd">{{hospital_state}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Hospital Pincode:</td>
                                    <td class="valuetd">{{HospPincode}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Hospitalization Date:</td>
                                    <td class="valuetd">{{DateOfAdmission}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Discharge Date:</td>
                                    <td class="valuetd">{{DateOfDischarge}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Document/Letters:</td>
                                    <td class="valuetd">
                                        <button class="btn btn-primary"
                                            style="background: #48807f; border: none; padding: 6px; border-radius: 6px;">
                                            View Documents
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- Policy Details -->
                <div class="accordion-item mt-4">
                    <h2 class="accordion-header" id="policyDetailsHeading">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#policyDetails">
                            Policy Details
                        </button>
                    </h2>
                    <div id="policyDetails" class="accordion-collapse collapse" data-bs-parent="#policyAccordion">
                        <div class="accordion-body">
                            <table class="table">
                                <tr>
                                    <td class="labeltd">Policy Type:</td>
                                    <td class="valuetd">{{policyType}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Policy Number:</td>
                                    <td class="valuetd">{{policyNumber}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Insurance Company:</td>
                                    <td class="valuetd">{{insuranceCompany}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Corporate Name:</td>
                                    <td class="valuetd">Persistent Systems Limited</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Policy Start Date:</td>
                                    <td class="valuetd">{{policyStartDate}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Policy End Date:</td>
                                    <td class="valuetd">{{policyEndDate}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Employee Name:</td>
                                    <td class="valuetd">{{employeeName}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Employee Email:</td>
                                    <td class="valuetd">{{employeeEmail}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Balance Sum Insured:</td>
                                    <td class="valuetd">{{BalanceSumInsured}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- Claim Tracker -->
                <div class="accordion-item mt-4">
                    <h2 class="accordion-header" id="claimTrackerHeading">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#claimTracker">
                            Claim Tracker
                        </button>
                    </h2>
                    <div id="claimTracker" class="accordion-collapse collapse" data-bs-parent="#policyAccordion">
                        <div class="accordion-body">
                            <table class="table">
                                <tr>
                                    <td class="labeltd">Claim Approved Date:</td>
                                    <td class="valuetd">-</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Deficiency Reason:</td>
                                    <td class="valuetd">{{Deficiency_Reason}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Reject Date:</td>
                                    <td class="valuetd">{{Reject_date}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Reject Reason:</td>
                                    <td class="valuetd">{{Reject_Reason_short}}</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Deficiency Raised Date:</td>
                                    <td class="valuetd">-</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Deficiency Expected Closure Date:</td>
                                    <td class="valuetd">-</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Deficiency Actual Closure Date:</td>
                                    <td class="valuetd">-</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Deficiency First Reminder:</td>
                                    <td class="valuetd">-</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Deficiency Second Reminder:</td>
                                    <td class="valuetd">-</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Deduction Reason:</td>
                                    <td class="valuetd">-</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- Expense Details -->
                <div class="accordion-item mt-4">
                    <h2 class="accordion-header" id="expenseDetailsHeading">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#expenseDetails">
                            Expense Details
                        </button>
                    </h2>
                    <div id="expenseDetails" class="accordion-collapse collapse" data-bs-parent="#policyAccordion">
                        <div class="accordion-body">
                            <table class="table">
                                <tr>
                                    <td class="labeltd">Claimed Amount:</td>
                                    <td class="valuetd">₹14,609.00</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Reported Amount:</td>
                                    <td class="valuetd">₹14,609.00</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Settled Amount:</td>
                                    <td class="valuetd">₹14,609.00</td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Deduction Amount:</td>
                                    <td class="valuetd">₹8,876.00</td>
                                </tr>
                            </table>
                            <p class="bs-title" style="margin-top: 20px; margin-bottom: 10px;">Charges</p>
                            <hr class="hrcls">
                            <table class="table">
                                <tr>
                                    <td class="labeltd">Billed Amount:</td>
                                    <td class="valuetd">
                                        <span style="padding: 0px 8px; background-color: #f3fbfe; border-radius: 50px; color: #2a6a87;">
                                            <b>14609.00</b>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Claim Approved Amount:</td>
                                    <td class="valuetd">
                                        <span style="padding: 0px 8px; background-color: #f3fbfe; border-radius: 50px; color: #2a6a87;">
                                            <b>14609.00</b>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Claim Settled Amount:</td>
                                    <td class="valuetd">
                                        <span style="padding: 0px 8px; background-color: #f3fbfe; border-radius: 50px; color: #2a6a87;">
                                            <b>14609.00</b>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Claim Incurred Amount:</td>
                                    <td class="valuetd">
                                        <span style="padding: 0px 8px; background-color: #f3fbfe; border-radius: 50px; color: #2a6a87;">
                                            <b>8876.00</b>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Surgery Charges:</td>
                                    <td class="valuetd">
                                        <span style="padding: 0px 8px; background-color: #f3fbfe; border-radius: 50px; color: #2a6a87;">
                                            <b>299.00</b>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Medicine Charges:</td>
                                    <td class="valuetd">
                                        <span style="padding: 0px 8px; background-color: #f3fbfe; border-radius: 50px; color: #2a6a87;">
                                            <b>8876.00</b>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="labeltd">Claimed Amount:</td>
                                    <td class="valuetd">
                                        <span style="padding: 0px 8px; background-color: #f3fbfe; border-radius: 50px; color: #2a6a87;">
                                            <b>14609.00</b>
                                        </span>
                                    </td>
                                </tr>
                            </table>                        
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <app-footer></app-footer>
    </div>

</div>