<!-- Body -->
<div class="container-fluid page-body-wrapper">

  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <!-- Main Wrapper -->
  <div class="main-panel">
    <div class="content-wrapper pt-3">
      <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
        style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

      <h3 class="bs-title">Upload Claims Manual</h3>
      <hr class="mb-2 mt-4" style="height: 0px">

      <!-- Add -->
      <form [formGroup]="basicInfo" class="card p-4">
        <div class="row">

          <div class="col-sm-3">
            <div class="form-group">
              <label class="bs-form-label" for="company_id">Client <span class="redstar">*</span></label>
              <ng-select formControlName="company_id" id="company_id" (change)="getpolicytype()"
                [items]="companyDetails" bindLabel="name" bindValue="id" [placeholder]="'Select Client'"
                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.company_id.errors}">
              </ng-select>

              <ng-container *ngIf="basicInfoSubmitted && getBasicInformationFormControls.company_id.errors">
                <p class="error"
                  *ngIf="basicInfoSubmitted && getBasicInformationFormControls.company_id.errors.required">
                  Client is required
                </p>
              </ng-container>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group">
              <label class="bs-form-label" for="policy_type">Policy Type <span class="redstar">*</span></label>
              <select class="form-select" formControlName="policy_type" id="policy_type" (change)="getpolicynumbers()"
                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors}"
                required>
                <option value="" selected>Select Policy Type</option>
                <option *ngFor="let type of policy_type" [value]="type.sub_type_id">{{ type.name }}</option>
              </select>
              <ng-container *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors">
                <p class="error"
                  *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_type.errors.required">
                  Policy Type is required
                </p>
              </ng-container>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group">
              <label class="bs-form-label" for="policy_number">Policy Number <span class="redstar">*</span></label>
              <select class="form-select" formControlName="policy_number" id="policy_number"
                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_number.errors}"
                (change)="getsamplefile()" required>
                <option value="" selected>Select Policy Type</option>
                <option *ngFor="let type of policynumbers" [value]="type.id">{{ type.policy_name }} / {{
                  type.policy_number }}</option>
              </select>
              <ng-container *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_number.errors">
                <p class="error"
                  *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_number.errors.required">
                  Policy Number is required
                </p>
              </ng-container>
            </div>
          </div>


          <div class="row">

            <div class="row mt-2">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="bs-form-label" for="document_file">Upload File <span
                      class="redstar">*</span></label>
                  <label for="document_file" id="features-file-upload_document_file" class=" form-control"
                    style="text-align:center; cursor:pointer; padding: 5px;">
                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span
                      id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add
                      File</span>
                  </label>
                  <input id="document_file" type="file" (change)="uploadfile($event)" formControlName="document_file"
                    accept=".xlsx, .xls, .csv" enctype="multipart/form-data" style="display: none" />
                </div>
                <small class="error" style="display: none; font-weight:normal" id="document_file_error">Upload Member
                  File
                  Required</small>
                <small style="font-size: 12px; color:#605D62" id="policyfeatures_document_file">Upload only .xlsx, .xls,
                  .csv files</small>
              </div>
              <div class="col-sm-6" id="download_sample_file" style="text-align: center;margin: auto;">
                <div class="form-group mt-3">
                  <label class="bs-form-label" for=""></label>
                  <button (click)="ondownload('policyfeatures')" title="{{downloadbuttontooltip}}"
                    class="btn btn-primary m-3 mt-0"
                    style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;" id="downloadbtn">
                    Download Sample File</button>
                </div>
              </div>
            </div>

            <div class="col-sm-12" align="center">
              <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()" role="button">Save</button>
            </div>
          </div>
        </div>

      </form>

    </div>
    <app-footer></app-footer>
  </div>
</div>