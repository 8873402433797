<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>
  
    <!-- Main Wrapper -->
    <div class="main-panel">
      <div class="content-wrapper pt-3">    

        <h3 class="bs-title" style="line-height: unset;">My Services</h3>
        <hr class="mb-2" style="height: 0px">
        
        <div class="row" style="background: #fff; margin: 0px; border-radius: 6px;">          
          <div class="col-sm-6" style="padding: 20px;">
            <h4>Comming Soon...</h4>
          </div>
          <div class="col-sm-6" style="padding: 20px;">
            <img src="../../../../assets/img/policy/policybanner.png" style="margin: auto; display: block; width: 70%;" />
          </div>
        </div>        
        
      </div>
      <app-footer></app-footer>
    </div>
  
  </div>