import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class Service {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }

  getcompanyDetails(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('Token') })
    };
    return this.http.post(this.AUTH_API + 'masteremployer/listbyuserwise',
      data
      , httpOptions);
  }
  getpolicytype(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('Token') })
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/policytypebyclient',
      data
      , httpOptions);
  }
  getpolicynumbers(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('Token') })
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/policytypebyclient',
      data
      , httpOptions);
  }
  getclientBranchName(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('Token') })
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/clientbranchdropdown',
      data,
      httpOptions);
  }
  getsamplefile(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('Token') })
    };
    return this.http.post(this.AUTH_API + 'memberpolicy/samplexcelfile/' + data.master_policies_id,
      data
      , httpOptions);
  }
  uploadsamplefile(data: any): Observable<any> {
    let filedata = new FormData();

    filedata.append("tokenable_id", data.tokenable_id);
    filedata.append("broker_id", data.broker_id);    
    filedata.append("client_id", data.client_id);
    filedata.append("policy_type", data.policy_type);
    filedata.append("policy_number", data.policy_number);
    filedata.append("document_file", data.document_file);

    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('Token') })
    };
    return this.http.post(this.AUTH_API + '',
      filedata
      , httpOptions);
  }
  getPolicyuploaddoclist(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('Token') })
    };
    return this.http.post(this.AUTH_API + 'memberpolicy/policyuploaddoclist',
      data
      , httpOptions);
  }
  downloadfailedDcoument(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('Token') })
    };
    return this.http.post(this.AUTH_API + 'memberpolicy/errorexcelfile/' + data.uploadid,
      data
      , httpOptions);
  }

}
